import { logError } from "@telia/b2x-logging";
import { corpLogin } from "@telia/b2b-rest-client";
import { currentLanguage } from "@telia/b2b-i18n";

const packageName = "b2b-corp-login";

const B2B_BASE_URL = "/foretag/mybusiness";
const LOGIN_ERROR_PAGE = `${B2B_BASE_URL}/something-went-wrong?error-message-key=login-error`;

/**
 * Invokes the login flow.
 * Will first retrieve the url to the login flow from BE and thereafter redirect the user to the url.
 *
 * In case of error will direct the user to the general error page. With the return url set as the invoking page.
 *
 * @param returnUrl The return url to be used back into MyBusiness after successful corp-login, if omitted will default to B2B base url.
 * @param language Language to be used in login flow, if omitted wll default to currentLanguage from i18n.
 */
export const invokeLoginFlow = async (
  returnUrl = `${window.location.origin}${B2B_BASE_URL}`,
  language = currentLanguage(),
  prompt?: string
): Promise<void> => {
  try {
    const payload: corpLogin.LoginRequestDTO = { targetUrl: returnUrl };
    const response: corpLogin.LoginUrlResponseDTO =
      await corpLogin.LoginControllerService.initiateLoginUrl(payload, language, prompt);

    window.location.href = response.redirectUrl as string;
  } catch (error) {
    logError(packageName, `Could not fetch login URL. ${error}`);
    // Direct user to general error page ...
    window.location.href = `${window.location.origin}${LOGIN_ERROR_PAGE}&return_url=${window.location.href}`;
  }
};

/**
 * Run initiate logout with the SFSession key if present in the cookie store.
 *
 * @returns a redirect url
 */
export const logoutCurrentUser = async (): Promise<string | void> => {
  const sfSessionMatch = [...document.cookie.matchAll(/SFSession=([^;]*)/gm)]?.flat();
  const sfSession: string = sfSessionMatch?.[1];

  try {
    const response: corpLogin.LogoutResponseDto =
      await corpLogin.LogoutControllerService.initiateLogout(sfSession);
    return response.redirectUrl as string;
  } catch (error: unknown) {
    logError(packageName, `Could not initiate logout, ${error}`);
  }
};
